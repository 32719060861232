// import { env } from './envMapping';
// import { getCookie } from './getCookie';
// import logger from './logger';

export const prepareDestination = () => {
  if (process.env.NODE_ENV !== 'production') {
    window.sessionStorage.setItem('ACCT_LOG_LEVEL', 'DEBUG');
  }
  // logger.info(`Checking and clearing stale/deleted cep cookies`);
  // const sessionIdCookie = getCookie(`adsk-cep-session-id${env()}`);
  // const sessionTokenCookie = getCookie(`adsk-cep-session-token${env()}`);
  // if (
  //   (sessionIdCookie && sessionIdCookie.trim() === 'deleted') ||
  //   (sessionTokenCookie && sessionTokenCookie.trim() === 'deleted')
  // ) {
  //   const filteredCookies = document.cookie
  //     .split(';')
  //     .filter(
  //       c =>
  //         !(
  //           c.trim().startsWith(`adsk-cep-session-id${env()}`) ||
  //           c.trim().startsWith(`adsk-cep-session-token${env()}`)
  //         )
  //     );
  //   document.cookie = filteredCookies.join(';');
  // }
};

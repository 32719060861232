exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes spinner--2_R_s{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes spinner--2_R_s{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.emulationBanner--1YIFY{text-align:center;color:#fff;font-size:13px;text-transform:none;background-color:#7ccc13;height:35px;line-height:35px;width:100%}", ""]);

// exports
exports.locals = {
	"emulationBanner": "emulationBanner--1YIFY",
	"spinner": "spinner--2_R_s"
};
import React from 'react';
import { useBuildContext } from '@account-portal/feynman-core';

import { I18nProvider } from '@account-portal/ui-react-i18n';

export const I18nWrapper = ({ render: wrapperRenderer }) => {
  const { isCommercial = true } = useBuildContext();
  const language = !isCommercial ? 'en' : '';

  return (
    <I18nProvider
      locale={language}
      render={({ locale, loading }) => wrapperRenderer({ locale, loading })}
    />
  );
};

import React from 'react';
import styles from './FedrampHeader.less';
import Logo from './Logo/Logo';
import Title from './Title/Title';
import MeMenu from './MeMenu/MeMenu';

const FedrampHeader = () => (
  <div className={styles.containerSectionWrapper}>
    <div className={styles.topContainer}>
      <div className={styles.topLeftContainer}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
      </div>
      <div className={styles.topRightContainer}>
        <MeMenu />
      </div>
    </div>
    <div className={styles.bottomContainer}>
      <div className={styles.titleContainer}>
        <Title />
      </div>
    </div>
  </div>
);

export default FedrampHeader;

/* eslint-disable import/no-extraneous-dependencies, import/extensions */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import ContextProviders from './context/ContextProviders/ContextProviders';
import { I18nWrapper } from './components/I18nWrapper/I18nWrapper';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import HigShell from './components/Shell/HigShell';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import IFrameAlert from './components/IFrameAlert/IFrameAlert';
import InsertTealiumScript from './components/InsertTealiumScript/InsertTealiumScript';
import AppSwitch from './components/AppSwitch/AppSwitch';
import { prepareDestination } from './helpers/prepareDestination';

const App = () => {
  prepareDestination();
  return (
    <ContextProviders>
      <I18nWrapper
        render={({ locale: languageCode, loading }) => (
          <span>
            <IFrameAlert>
              <BrowserRouter>
                <React.Fragment>
                  <ScrollToTop />
                  <HigShell loading={loading}>
                    <ErrorBoundary>
                      <InsertTealiumScript languageCode={languageCode} />
                    </ErrorBoundary>
                    <AppSwitch />
                  </HigShell>
                </React.Fragment>
              </BrowserRouter>
            </IFrameAlert>
          </span>
        )}
      />
    </ContextProviders>
  );
};

export default App;

import React, { useContext, useState } from 'react';
import {
  AuthBoundaryContext,
  useBuildContext,
} from '@account-portal/feynman-core';

import NonCommercialHigHeader from './NonCommercialHigHeader';
import CommercialHigHeader from './CommercialHigHeader';

const tealiumTagsNoAccess = {
  contentName: 'top-nav-alerts',
  contentType: 'no-access',
};

const HigHeader = ({
  onBeforeNavigate,
  onNotificationsClose,
  onNotificationsOpen,
  loading,
}) => {
  const { authBoundary } = useContext(AuthBoundaryContext);
  const { isCommercial = true } = useBuildContext();
  const [accessToken, setAccessToken] = useState('');

  React.useEffect(() => {
    const getAccessToken = async () => {
      const token = await authBoundary.getAccessToken();
      setAccessToken(token);
    };

    getAccessToken();
  }, []);

  if (!isCommercial) {
    return accessToken && <NonCommercialHigHeader />;
  }

  return (
    <CommercialHigHeader
      onBeforeNavigate={onBeforeNavigate}
      onNotificationsClose={onNotificationsClose}
      onNotificationsOpen={onNotificationsOpen}
      accessToken={accessToken}
      tealiumTagsNoAccess={tealiumTagsNoAccess}
      loading={loading}
    />
  );
};

export default HigHeader;

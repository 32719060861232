import { ScriptError } from '@account-portal/ui-toolkit-react';

/*
 * This file runs before index.js
 *
 * We need to set all of the apps to the script error if
 * the script tag has not loaded already.  This prevents
 * the chassis from crashing completely should the app
 * 404 during deployments.
 *
 */

const isNonCommercial = () => {
  const { BUILD_FOR } = process.env;
  return BUILD_FOR && BUILD_FOR.toLowerCase() === 'federal';
};

export const commercialApps = [
  'ADSK_ACCOUNT_PORTAL_APP_REPORTING',
  'ADSK_ACCOUNT_PORTAL_APP_USER_ACCESS',
  'ADSK_ACCOUNT_PORTAL_APP_UMA',
  'ADSK_ACCOUNT_PORTAL_HOME',
  'ADSK_ACCOUNT_PORTAL_PPU_REPORTING',
  'ADSK_ACCOUNT_PORTAL_PRODUCTS',
  'ADSK_ACCOUNT_PORTAL_SUS_APP_REPORTING',
  'ADSK_ACCOUNT_PORTAL_ALL_ACCOUNT_EXPORT',
  'ADSK_ACCOUNT_PORTAL_INSIGHTS_RECOMMENDATION',
  'ADSK_ACCOUNT_PORTAL_USAGE_REPORT',
  'ADSK_ACCOUNT_PORTAL_BILLING',
  'ADSK_ACCOUNT_PORTAL_PRODUCT_DEPLOYMENTS',
  'ADSK_ACCOUNT_PORTAL_PRODUCTS_AND_SERVICES',
  'ADSK_ACCOUNT_PORTAL_APP_PROVISIONING',
];

export const nonCommercialApps = [
  'ADSK_ACCOUNT_PORTAL_APP_USER_ACCESS',
  'ADSK_ACCOUNT_PORTAL_APP_UMA',
];

export const apps = isNonCommercial() ? nonCommercialApps : commercialApps;

export const initializeAppGlobals = variableName => {
  if (window[variableName] === undefined) {
    window[variableName] = ScriptError;
  }
};

apps.forEach(app => initializeAppGlobals(app));

import getPkceAuthClient from '../pkceclient';
import logger from './logger';

export const getAccessTokenFromPkceClient = async () => {
  let token = null;

  const auth0 = getPkceAuthClient();
  try {
    token = await auth0.getTokenSilently();
  } catch (error) {
    logger.error(error, 'AUTH_HELPER: Redirecting to login page...');
    await auth0.loginWithRedirect();
  }

  return token;
};

export const initializeSession = async (axios, token) => {
  logger.info(
    process.env.NODE_ENV,
    'Updating cep session with new refreshed token',
    token
  );
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(`gatekeeper/v1/initialize`, '', { headers }).then(() => {
    logger.info('Successfully injected the session.');
  });
};

export const terminateSession = async axios =>
  axios.get('/gatekeeper/v1/terminate');

export const getAccessToken = async axios => {
  const response = await axios.get('/service/authentication/access-token');
  return response.data.accessToken;
};

export const refreshAccessToken = async axios => {
  try {
    const response = await axios.post('/service/authentication/refresh-token');
    return response.data.accessToken;
  } catch (err) {
    logger.error(err, 'Failed to refresh access token ');
    return '';
  }
};

export const logout = async (returnUrl = '') => {
  const auth0 = getPkceAuthClient();
  try {
    const logoutOption = { post_logout_redirect_uri: returnUrl };
    await auth0.logout(logoutOption);
  } catch (error) {
    logger.error(error, 'Failed to logout ');
  }
};

const envMap = {
  '': 'stg',
  'dev-manage': 'stg',
  'local-manage': 'stg',
  'qa.stg-manage': 'stgqa',
  'stg-manage': 'stg',
  'int-manage': 'int',
  'qa.manage': 'prdqa',
  manage: 'prd',
};

export const getEnvSpecificUrl = (url, host = window.location.hostname) => {
  const hostRegex = /.autodesk.com/;
  const splitHost = (hostRegex.test(host) && host.split(hostRegex)[0]) || '';
  const env = envMap[splitHost];
  if (env.endsWith('qa')) {
    return `https://qa.${url.split('://')[1]}`;
  }
  return url;
};

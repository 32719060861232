/* eslint-disable react/sort-comp */
import React from 'react';
import { withAxios } from '@account-portal/react-axios';
import {
  BuildContext,
  AuthBoundary,
  AuthBoundaryContext,
} from '@account-portal/feynman-core';
import { isBetaMode } from '../../helpers/isBetaMode';
import {
  getAccessTokenFromPkceClient,
  getAccessToken,
} from '../../helpers/authHelpers';
import { ClientAuthHandler } from '../../ClientAuthHandler';
import {
  parseAndSaveReturnUrl,
  redirectToReturnUrlIfAvailable,
} from '../../helpers/returnUrl';
import { isCepSessionValid } from '../../helpers/isCepSessionValid';
import logger from '../../helpers/logger';

class AuthBoundaryProvider extends React.Component {
  // eslint-disable-next-line no-undef
  static contextType = BuildContext;

  constructor(props) {
    super(props);

    this.clientAuthHandler = null;
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    let token = '';

    const { axios } = this.props;
    const { isCommercial = true } = this.context;
    this.clientAuthHandler = new ClientAuthHandler(axios, isCommercial);

    if (isBetaMode()) {
      await (async () => {
        await parseAndSaveReturnUrl();
        token = await getAccessTokenFromPkceClient();
        if (token) {
          this.clientAuthHandler.setAccessToken(token);
          const headers = {
            Authorization: `Bearer ${token}`,
          };

          if (isCepSessionValid()) {
            logger.info(
              process.env.NODE_ENV,
              'No need for re-initializing session'
            );
            return Promise.resolve().then(() => {
              this.setState({ loading: false });
            });
          }
          logger.info(
            process.env.NODE_ENV,
            'Injecting a new session into cep using the token: ',
            token
          );

          await axios
            .post(`gatekeeper/v1/initialize`, '', { headers })
            .then(() => {
              logger.info('Successfully injected the session.');
              this.setState({ loading: false });
              redirectToReturnUrlIfAvailable();
            });
        }
        return true;
      })();
    } else {
      await (async () => {
        token = isCommercial
          ? await getAccessToken(axios)
          : await getAccessTokenFromPkceClient();
        if (token) {
          this.clientAuthHandler.setAccessToken(token);
          this.setState({ loading: false });
        }
      })();
    }
  }

  render() {
    const { children } = this.props;
    const { loading } = this.state;

    return (
      !loading && (
        <AuthBoundaryContext.Provider
          value={{
            authBoundary: AuthBoundary.createAuthBoundary(
              this.clientAuthHandler
            ),
          }}
        >
          {children}
        </AuthBoundaryContext.Provider>
      )
    );
  }
}

const AuthBoundaryProviderWithAxios = withAxios(AuthBoundaryProvider);

export default AuthBoundaryProviderWithAxios;

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerSectionWrapper--3wEkM{background-color:#000;border-bottom:1px solid #0a0a0a;font-size:16px}.containerSectionWrapper--3wEkM .topContainer--2_zkA{display:-ms-flexbox;display:flex;font-size:62.5%;height:3.6em;-ms-flex-pack:justify;justify-content:space-between;min-width:20em;padding:1em;white-space:nowrap;-ms-flex-line-pack:center;align-content:center}@media (min-width:1040px){.containerSectionWrapper--3wEkM .topContainer--2_zkA{margin-left:5px}}.containerSectionWrapper--3wEkM .topContainer--2_zkA .topLeftContainer--3LpDm,.containerSectionWrapper--3wEkM .topContainer--2_zkA .topRightContainer--2ypyY{-ms-flex-item-align:center;align-self:center;display:-ms-flexbox;display:flex}.containerSectionWrapper--3wEkM .topContainer--2_zkA .logoContainer--3M5P4:focus-within{outline:1.5px solid #5f60ff}.containerSectionWrapper--3wEkM .bottomContainer--281kz{-webkit-box-shadow:0 -1px 0 #333;box-shadow:0 -1px 0 #333;letter-spacing:normal;background-color:#0a0a0a;color:#fff;letter-spacing:.05px;margin:auto;max-height:4em}.containerSectionWrapper--3wEkM .bottomContainer--281kz .titleContainer--3t-qM{-ms-flex-item-align:center;align-self:center;display:-ms-flexbox;display:flex}", ""]);

// exports
exports.locals = {
	"containerSectionWrapper": "containerSectionWrapper--3wEkM",
	"topContainer": "topContainer--2_zkA",
	"topLeftContainer": "topLeftContainer--3LpDm",
	"topRightContainer": "topRightContainer--2ypyY",
	"logoContainer": "logoContainer--3M5P4",
	"bottomContainer": "bottomContainer--281kz",
	"titleContainer": "titleContainer--3t-qM"
};
import React from 'react';
import TextLink from '@digital-hig/text-link';
import AutodeskLogo from '../images/autodesk-logo-new.svg';
import styles from './Logo.less';

const Logo = () => (
  <TextLink
    className={styles.autodeskLogo}
    underline="none"
    href="https://www.autodesk.com"
    data-wat-loc="uh top bar"
    data-wat-val="logo: adsk"
    data-testid="uh-autodesk-logo-link"
  >
    <AutodeskLogo className={styles.autodeskLogo} />
  </TextLink>
);

export default Logo;

const envcode = () =>
  ({
    test: 'test',
    development: 'dev',
    stage: 'stg',
    integration: 'int',
    production: '',
  }[process.env.NODE_ENV]);

export const env = (conf = { prefix: '-', suffix: '' }) =>
  `${conf.prefix}${envcode()}${conf.suffix}`;

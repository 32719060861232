/* eslint-disable import/no-extraneous-dependencies, import/extensions */

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { Component as UserAccess } from '@account-portal/app-user-access';
import { AppErrorBoundary } from '@account-portal/ui-toolkit-react';

const NonCommercialApp = () => (
  <Switch>
    <Route
      path="/user-access"
      render={props => (
        <AppErrorBoundary key="/user-access">
          <UserAccess {...props} />
        </AppErrorBoundary>
      )}
    />

    <Route path="/" render={() => <Redirect to="/user-access" />} />
  </Switch>
);

export default NonCommercialApp;

import { env } from './envMapping';
import { getCookie } from './getCookie';
import logger from './logger';

const nonBetaTestingSupportedEnvs = ['development', 'integration'];

export const isBetaMode = () => {
  let value = false;
  if (nonBetaTestingSupportedEnvs.includes(process.env.NODE_ENV)) {
    logger.info(
      `isBetaMode: true - since ${process.env.NODE_ENV} is not a beta testing env`
    );
    return true;
  }
  logger.info(
    `Checking beta cookie account-beta-mode${env({
      prefix: process.env.NODE_ENV === 'production' ? '' : '-',
      suffix: '',
    })}`
  );
  const cookieName = `account-beta-mode${env({
    prefix: process.env.NODE_ENV === 'production' ? '' : '-',
    suffix: '',
  })}`;
  const cookieValue = getCookie(cookieName);
  logger.info(`isBetaMode: ${cookieName}=${cookieValue}`);
  if (cookieValue) {
    value = !!cookieValue;
  }
  logger.info(`isBetaMode: ${value}`);
  return value;
};

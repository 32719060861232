class IdleTimer {
  constructor({
    onTimeout,
    getAccessToken,
    timeout = 1800000,
    inactiveIntervalTime = 1000,
    refreshIntervalTime = 300000,
    userEventLagTime = 300,
  }) {
    this.timeout = timeout;
    this.inactiveIntervalTime = inactiveIntervalTime;
    this.refreshIntervalTime = refreshIntervalTime;
    this.userEventLagTime = userEventLagTime;
    this.onTimeout = onTimeout;
    this.getAccessToken = getAccessToken;

    const expiredTime = JSON.parse(window.localStorage.getItem('_expiredTime'));

    if (expiredTime && expiredTime < Date.now()) {
      this.cleanUp();
      this.onTimeout();
      return;
    }
    this.startAuthInterval();
    this.startRefreshInterval();
  }

  startAuthInterval() {
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.updateExpiredTime();

    this.authInterval = setInterval(() => {
      const expiredTime = JSON.parse(
        window.localStorage.getItem('_expiredTime')
      );

      if (!expiredTime) {
        this.cleanUp();
      } else if (expiredTime > 0 && expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.cleanUp();
          this.onTimeout();
        }
      }
    }, this.inactiveIntervalTime);
  }

  startRefreshInterval() {
    this.refreshInterval = setInterval(async () => {
      if (this.getAccessToken) {
        await this.getAccessToken();
      }
    }, this.refreshIntervalTime);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.timeoutTracker = setTimeout(() => {
      window.localStorage.setItem(
        '_expiredTime',
        JSON.stringify(Date.now() + this.timeout)
      );
    }, this.userEventLagTime);
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    window.localStorage.removeItem('_expiredTime');
    clearInterval(this.authInterval);
    clearInterval(this.refreshInterval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}
export default IdleTimer;

export const getCookie = cookieName => {
  let cookieValue;
  const cookies = document.cookie
    .split(';')
    .filter(c => c.trim().startsWith(cookieName));
  if (cookies && cookies.length > 0) {
    [, cookieValue = ''] = cookies[0].split('=');
  }
  return cookieValue ? cookieValue.trim() : null;
};

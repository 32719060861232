import { AuthClient } from '@adsk/auth-pkce-sdk';
import { getEnvSpecificUrl } from './helpers/getEnvSpecificUrl';

const env = process.env.ADSK_AUTH_V2_ENV;
const clientId = process.env.ADSK_AUTH_CLIENT_ID;
const redirectUrl = process.env.ADSK_AUTH_REDIRECT_URL;
const pkceRedirectUrl = process.env.ADSK_PKCE_REDIRECT_URL;
const scope = 'data:read user:read';

const getPkceAuthClient = () =>
  new AuthClient({
    env,
    client_id: clientId,
    redirect_uri: getEnvSpecificUrl(redirectUrl),
    redirect_uri_iframe: getEnvSpecificUrl(pkceRedirectUrl),
    scope,
    useRefreshTokens: true,
  });

export default getPkceAuthClient;

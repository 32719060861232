const setCustomerType = modules => {
  if (modules.length > 0) {
    // eslint-disable-next-line dot-notation

    const userManagementItems = modules.filter(
      mod => mod.label === 'USER_MANAGEMENT'
    );

    if (userManagementItems.length === 0) {
      window.digitalData.user.userManagementType = 'Not a user manager';
    } else {
      const userManagementLinks = userManagementItems[0].children;

      let isAUM = false;
      let isNAMU = false;
      let isHybrid = false;

      userManagementLinks.forEach(item => {
        switch (item.label) {
          case 'BY_USER':
          case 'BY_PRODUCT':
            isAUM = true;
            break;

          default:
            isNAMU = true;
            break;
        }
      });

      if (isAUM && isNAMU) {
        isHybrid = true;
      }

      if (isHybrid) {
        window.digitalData.user.userManagementType = 'Hybrid';
      } else if (isAUM) {
        window.digitalData.user.userManagementType = 'AUM';
      } else {
        window.digitalData.user.userManagementType = 'NAMU';
      }
    }
  }
};

export default setCustomerType;

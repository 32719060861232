import React from 'react';
import cls from 'classnames';
import FedrampHeader from '../../Header/FedHeader/FedrampHeader';
import { pageHeaderWrapper } from './HigHeader.less';

// eslint-disable-next-line no-empty-pattern
const NonCommercialHigHeader = () => (
  <div className={cls(pageHeaderWrapper, 'adsk-account-page-header')}>
    <FedrampHeader />
  </div>
);

export default NonCommercialHigHeader;

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pageHeaderWrapper--2PK9j{position:relative}.toastWrapper--ojcuL>div{z-index:1000;position:absolute;top:68px;margin-right:44px}", ""]);

// exports
exports.locals = {
	"pageHeaderWrapper": "pageHeaderWrapper--2PK9j",
	"toastWrapper": "toastWrapper--ojcuL"
};
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { withAxios } from '@account-portal/react-axios';
import { TealiumTags } from '@account-portal/feynman-core';
import { getI18nInstance } from '@account-portal/ui-react-i18n';
import { getLocale } from '../../../helpers/universalHeaderLocale';
import { ClientAuthHandler } from '../../../ClientAuthHandler';

const loadScript = ({
  countryCode,
  languageCode,
  i18nInstance,
  axios,
  onSignOut,
  onBeforeNavigate,
  showAlerts,
  accessToken,
  env,
  isCommercial,
}) => {
  const environment =
    env === 'production'
      ? 'prd'
      : env === 'stage'
      ? 'stg'
      : env === 'integration'
      ? 'int'
      : 'dev';

  const meMenuConfig = {
    meMenu: {
      onSignIn: () => {},
      onSignOut,
      onBeforeNavigate,
    },
  };

  const notificationsConfig = {
    notification: {
      axios,
      i18n: i18nInstance,
    },
  };

  const components = {
    ...meMenuConfig,
    ...(showAlerts && notificationsConfig),
  };

  const config = {
    property: isCommercial ? 'accounts-portal' : 'fed-ramp',
    language: getLocale(languageCode, countryCode),
    environment,
    components,
    accessToken,
  };

  /* istanbul ignore else */
  if (
    window.adsk &&
    window.adsk.components &&
    window.adsk.components.universalHeader
  ) {
    window.adsk.components.universalHeader.render(
      'uh-header-container',
      config
    );
  }
};

const appendScript = params => {
  const script = document.createElement('script');
  script.src = process.env.ADSK_UNIVERSAL_HEADER_SCRIPT;
  script.type = 'text/javascript';
  script.onload = () => loadScript({ ...params });
  document.body.appendChild(script);
};

class UniversalHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activateLogout: false };
  }

  componentDidMount() {
    const {
      accessToken,
      countryCode,
      languageCode,
      env,
      axios,
      onBeforeNavigate,
      showAlerts,
      isCommercial,
    } = this.props;

    const i18nInstance = getI18nInstance();

    appendScript({
      accessToken,
      countryCode,
      languageCode,
      env,
      axios,
      onBeforeNavigate,
      showAlerts,
      i18nInstance,
      isCommercial,
      onSignOut: this.meMenuSignOut,
    });
  }

  meMenuSignOut = () => {
    sessionStorage.clear();
    this.setState({ activateLogout: true });
  };

  render() {
    const { tealiumTags, axios, isCommercial } = this.props;

    if (this.state.activateLogout) {
      const authHandler = new ClientAuthHandler(axios, isCommercial);
      authHandler.signout();
    }

    return (
      <React.Fragment>
        <TealiumTags {...tealiumTags}>
          <div id="uh-header-container" />
        </TealiumTags>
      </React.Fragment>
    );
  }
}

UniversalHeader.defaultProps = {
  onBeforeNavigate: () => {},
  languageCode: 'en',
  countryCode: 'US',
  accessToken: '',
  showAlerts: false,
  isCommercial: true,
  tealiumTags: {},
};

const UniversalHeaderWithAxios = withAxios(UniversalHeader);

export default UniversalHeaderWithAxios;

import React from 'react';
import { I18n } from 'react-i18next';
import ThemeMui from '@digital-hig/theme-mui';
import Typography from '@digital-hig/typography';
import styles from './Title.less';

const Logo = () => (
  <I18n ns="navigation">
    {t => (
      <ThemeMui
        overrides={{
          MuiTypography: {
            root: {
              color: '#fff',
              fontSize: '1em',
            },
          },
        }}
        themePrefix="light"
      >
        <div className={styles.titleContainer}>
          <Typography>{t('ACCOUNT_FOR_GOVERNMENT')}</Typography>
        </div>
      </ThemeMui>
    )}
  </I18n>
);

export default Logo;

import React from 'react';
import { translate } from '@account-portal/ui-react-i18n';
import { FeatureFlagsContainer } from '@account-portal/feature-flags';
import { Banner } from '@account-portal/ui-toolkit-react';

const MaintenanceBanner = ({ t }) => (
  <FeatureFlagsContainer
    flag="dr-maintenance-banner"
    render={({ flags: drBannerFlag, loading: drFlagLoading }) => {
      if (drFlagLoading) return null;
      if (drBannerFlag) {
        return (
          <Banner type="warning">{t('MAINTENANCE_BANNER_MESSAGE')}</Banner>
        );
      }
      return null;
    }}
  />
);

export default translate('error-pages')(MaintenanceBanner);

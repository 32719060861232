import { env } from './envMapping';
import { getCookie } from './getCookie';
import logger from './logger';

const lessThanOneHourAgo = date => {
  logger.info(`Validating creation date: ${date}`);
  const dateToCheck = new Date(date);
  const SESSION_LIMIT = 1000 * 60 * 57; // Checking only till 57 Minutes
  const aboutAnHourAgo = Date.now() - SESSION_LIMIT;
  return dateToCheck.getTime() > aboutAnHourAgo;
};

export const isCepSessionValid = () => {
  const envCode = env({
    prefix: process.env.NODE_ENV === 'production' ? '' : '-',
    suffix: '',
  });
  logger.info(`Checking if cep session exists and was created recently`);
  const sessionIdCookie = getCookie(`adsk-cep-session-id${envCode}`);
  const sessionTokenCookie = getCookie(`adsk-cep-session-token${envCode}`);
  const sessionCreationDateCookie = getCookie(
    `adsk-cep-session-creationdate${envCode}`
  );
  if (sessionIdCookie && sessionTokenCookie && sessionCreationDateCookie) {
    return lessThanOneHourAgo(sessionCreationDateCookie);
  }
  return false;
};

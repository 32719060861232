import React from 'react';
import PropTypes from 'prop-types';
import { UserProfileContainer } from '@account-portal/user-profile';
import { NavContainer } from '@account-portal/nav';
import { withRouter } from 'react-router-dom';
import {
  TealiumContainer,
  useBuildContext,
} from '@account-portal/feynman-core';

import setCustomerType from './utils/setCustomerType';
import './InsertTealiumScript.less';

const setOxygenId = ({ oxygenId, countryCode }) => {
  window.digitalData.user.oxygenID = oxygenId;
  window.digitalData.user.country = countryCode;
  window.digitalData.user.loginStatus = 'logged in';
};

const InsertTealiumScript = ({ languageCode }) => {
  const { isCommercial = true } = useBuildContext();

  if (!isCommercial) return null;

  return (
    <React.Fragment>
      <TealiumContainer
        tealiumName="manage"
        env={process.env.NODE_ENV}
        languageCode={languageCode}
      />
      <UserProfileContainer
        render={({ user }) => {
          if (user) {
            setOxygenId(user);
          }
          return null;
        }}
      />
      <NavContainer
        render={({ modules }) => {
          if (modules) {
            setCustomerType(modules);
          }
          return null;
        }}
      />
    </React.Fragment>
  );
};

InsertTealiumScript.propTypes = {
  languageCode: PropTypes.string,
};

InsertTealiumScript.defaultProps = {
  languageCode: 'en',
};

export default withRouter(InsertTealiumScript);

import React from 'react';
import {
  NavContainer,
  SideNav,
  QuickLinksContainer,
} from '@account-portal/nav';

import { useBuildContext } from '@account-portal/feynman-core';

const HigNav = ({ loading, location, onBeforeNavigate }) => {
  const { isCommercial = true } = useBuildContext();

  return (
    <NavContainer
      isCommercial={isCommercial}
      render={({ modules, loading: navLoading }) =>
        isCommercial ? (
          <QuickLinksContainer
            location={location}
            render={({ links }) => (
              <SideNav
                isCommercial={isCommercial}
                modules={modules}
                loading={loading || navLoading}
                quickLinks={links}
                onBeforeNavigate={onBeforeNavigate}
              />
            )}
          />
        ) : (
          <SideNav
            isCommercial={isCommercial}
            modules={modules}
            loading={loading || navLoading}
            onBeforeNavigate={onBeforeNavigate}
          />
        )
      }
    />
  );
};

export default HigNav;

import { env } from './envMapping';
import { getEnvSpecificUrl } from './getEnvSpecificUrl';
import logger from './logger';

const envBaseUrl =
  process.env.ADSK_LOCAL === 'true'
    ? 'https://local-manage.autodesk.com:5000'
    : `https://${env({
        prefix: '',
        suffix: process.env.NODE_ENV !== 'production' ? '-' : '',
      })}manage.autodesk.com`;

const isLandingPageUrl = url => {
  const baseUrl = getEnvSpecificUrl(envBaseUrl);
  return (
    url === `${baseUrl}` ||
    url === `${baseUrl}/` ||
    url === `${baseUrl}/home` ||
    url === `${baseUrl}/home/` ||
    url === `${baseUrl}/index.html`
  );
};

/*
This function evaluates the current page url and check if it is the default landing
page with a return url. In case it is, the return url is saved to a local storage 
and is used for redirect later once session is initialized.
*/
export const parseAndSaveReturnUrl = async (url = window.location.href) => {
  logger.info(`Checking URL: ${url}`);
  if (isLandingPageUrl(url)) {
    return;
  }
  logger.info(`Not a landing url`);
  let returnUrl;
  let comps = url.split('?');
  const requestedUrlBase = comps[0];
  logger.info(
    `Checking requestedUrlBase ${requestedUrlBase} against ${envBaseUrl}`
  );
  if (isLandingPageUrl(requestedUrlBase)) {
    logger.info(`${requestedUrlBase} is landing page url`);
    if (comps.length > 1) {
      comps = comps[1].split('&').filter(c => c.startsWith('returnUrl'));
      if (comps.length > 0) {
        [, returnUrl] = comps[0].split('=');
      }
    }
    logger.info(
      `requestedUrlBase = ${requestedUrlBase} returnUrl = ${returnUrl}`
    );
  } else {
    returnUrl = window.location.href;
  }

  if (returnUrl) {
    logger.info(`setting return url: ${returnUrl}`);
    window.localStorage.setItem(`adsk-account${env()}`, returnUrl);
  }
  logger.info(`Done with return url handling`);
};

/*
This function makes an attempt to redirect to a return url saved in localstorage earlier before login.
It redirects only if the current url is not same as return url.
It clears the return url from storage by default.
*/
export const redirectToReturnUrlIfAvailable = () => {
  const returnUrl = window.localStorage.getItem(`adsk-account${env()}`);
  if (returnUrl) {
    if (window.location.href.startsWith(returnUrl)) {
      logger.info(`Removed any stored return url: ${returnUrl}`);
      window.localStorage.removeItem(`adsk-account${env()}`);
    } else {
      logger.info(`Redirecting to stored return url: ${returnUrl}`);
      window.location.href = returnUrl;
    }
  }
};

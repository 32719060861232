import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    /* istanbul ignore else */
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const appContainer = document.getElementById(
        'app-chassis-scroll-container'
      );
      /* documentMode is only present in ie11 */
      const { documentMode } = document;

      if (typeof documentMode === 'undefined') appContainer.scrollTo(0, 0);
      else appContainer.scrollTop = 0;
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);

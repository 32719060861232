const { log } = console;

const LOG_LEVELS = {
  ERROR: 0,
  INFO: 1,
  DEBUG: 2,
};

const logLevel = () =>
  LOG_LEVELS[window.sessionStorage.getItem('ACCT_LOG_LEVEL') || 'ERROR'];

const logger = {
  debug: (...args) =>
    logLevel() >= 2
      ? log(`${new Date().toISOString()} | DEBUG | ${args.join(' ')}`)
      : '',
  info: (...args) =>
    logLevel() >= 1
      ? log(`${new Date().toISOString()} | INFO | ${args.join(' ')}`)
      : '',
  error: (err, ...args) =>
    log(
      `${new Date().toISOString()} | ERROR | ${args.join(' ')}`,
      err ? `\n${err.stack}` : ''
    ),
};

export default logger;

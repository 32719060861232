exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".autodeskLogo--1oujr{width:14em;height:100%;-ms-flex-align:center;align-items:center}@media (max-width:1040px){.autodeskLogo--1oujr{height:100%;width:10em;margin-right:.5em}}", ""]);

// exports
exports.locals = {
	"autodeskLogo": "autodeskLogo--1oujr"
};
import React, { Suspense, lazy, useContext, useEffect } from 'react';
import {
  useBuildContext,
  AuthBoundaryContext,
} from '@account-portal/feynman-core';

import NonCommercialApp from './NonCommercialApp';
import IdleTimer from '../../helpers/idleTimer';
/* istanbul ignore next */
export const CommercialApp = lazy(() => import('./CommercialApp'));

const AppSwitch = () => {
  const { isCommercial = true } = useBuildContext();

  const { authBoundary } = useContext(AuthBoundaryContext);

  useEffect(() => {
    const it = new IdleTimer({
      onTimeout: () => {
        authBoundary.signout();
      },
      getAccessToken: async () => {
        const token = await authBoundary.getAccessToken();
        await authBoundary.setAccessToken(token);
      },
      timeout: parseInt(process.env.ADSK_TIMEOUT, 10),
      inactiveIntervalTime: parseInt(
        process.env.ADSK_INACTIVE_INTERVAL_TIME,
        10
      ),
      refreshIntervalTime: parseInt(process.env.ADSK_REFRESH_INTERVAL_TIME, 10),
      userEventLagTime: parseInt(process.env.ADSK_USER_EVENT_LAG_TIME, 10),
    });
    return () => {
      it.cleanUp();
    };
  }, []);

  if (!isCommercial) {
    return <NonCommercialApp />;
  }

  return (
    <Suspense fallback={<div>Loading</div>}>
      <CommercialApp />
    </Suspense>
  );
};

export default AppSwitch;

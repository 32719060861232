exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".titleContainer--2dFvR{padding:.5em;margin-left:.5em}", ""]);

// exports
exports.locals = {
	"titleContainer": "titleContainer--2dFvR"
};
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import cls from 'classnames';
import {
  UserProfileContainer,
  UserPrivilegesContainer,
} from '@account-portal/user-profile';
import {
  Notifications,
  MessagePollingContainer,
} from '@account-portal/notifications';
import { UniversalHelp } from '@account-portal/feynman-core';

import EmulationBanner from '../EmulationBanner/EmulationBanner';
import MaintenanceBanner from '../../Maintenance/MaintenanceBanner';
import { toastWrapper, pageHeaderWrapper } from './HigHeader.less';
import UniversalHeader from '../UniversalHeader/UniversalHeader';

const CommercialHigHeader = ({
  onBeforeNavigate,
  onNotificationsClose,
  onNotificationsOpen,
  accessToken,
  tealiumTagsNoAccess,
  loading,
}) => (
  <Fragment>
    {!loading && (
      <Notifications
        onOpen={onNotificationsOpen}
        onClose={onNotificationsClose}
      />
    )}
    <MaintenanceBanner />
    <UserProfileContainer
      render={({ user }) => {
        const loggedInUser = user && user.loggedInUser;
        return (
          <div className={cls(pageHeaderWrapper, 'adsk-account-page-header')}>
            <UserPrivilegesContainer
              render={({ userPrivileges, loading: loadingPrivileges }) => {
                const showAlerts =
                  !loadingPrivileges &&
                  userPrivileges.includes('ManageAlertNotifications');
                const tealiumTags = !showAlerts
                  ? tealiumTagsNoAccess
                  : undefined;

                if (loadingPrivileges || !loggedInUser || !accessToken)
                  return null;

                return (
                  <UniversalHeader
                    onBeforeNavigate={onBeforeNavigate}
                    languageCode={loggedInUser.languageCode}
                    countryCode={loggedInUser.countryCode}
                    accessToken={accessToken}
                    showAlerts={showAlerts}
                    tealiumTags={tealiumTags}
                  />
                );
              }}
            />
            <EmulationBanner user={user} />
            {!loading && (
              <MessagePollingContainer
                interval="600000"
                render={result => <div className={toastWrapper}>{result}</div>}
              />
            )}
            {loggedInUser && (
              <UniversalHelp
                languageCode={loggedInUser.languageCode || 'en'}
                countryCode={loggedInUser.countryCode || 'us'}
              />
            )}
          </div>
        );
      }}
    />
  </Fragment>
);

export default CommercialHigHeader;

import jwt from 'jsonwebtoken';
import { utils } from '@account-portal/ui-toolkit-react';
import {
  getAccessTokenFromPkceClient,
  refreshAccessToken,
  logout,
  terminateSession,
  initializeSession,
} from './helpers/authHelpers';
import { isBetaMode } from './helpers/isBetaMode';

export class ClientAuthHandler {
  constructor(axios, isCommercial = true) {
    // This buffer helps refresh the access token 5 seconds (as configured here) earlier
    // before the token expires.
    this.expirationBufferInSeconds = 5;
    this.accessToken = null;
    this.axios = axios;
    this.isCommercial = isCommercial;
  }

  getAccessToken() {
    return this.accessToken;
  }

  setAccessToken(newToken) {
    this.accessToken = newToken;
  }

  async refreshAccessToken() {
    if (isBetaMode()) {
      const token = await getAccessTokenFromPkceClient();
      if (this.accessToken !== token) {
        this.accessToken = token;
        await initializeSession(this.axios, token);
      }
    } else {
      this.accessToken = this.isCommercial
        ? await refreshAccessToken(this.axios)
        : await getAccessTokenFromPkceClient();
    }
  }

  isTokenValid() {
    const token = jwt.decode(this.accessToken);

    if (!token) return false;

    const expiresAt = token.exp * 1000;
    const expiresIn =
      expiresAt - this.expirationBufferInSeconds * 1000 - new Date().getTime();
    return expiresIn > 0;
  }

  async signout(returnUrl = window.location.href) {
    if (isBetaMode()) {
      await terminateSession(this.axios);
      await logout(returnUrl);
    } else if (!this.isCommercial) {
      await logout(process.env.ADSK_AUTH_REDIRECT_URL);
    } else {
      const authLogoutUrl = '/service/authentication/logout?returnUrl=';
      utils.navigatePage(authLogoutUrl + encodeURIComponent(returnUrl));
    }
  }
}

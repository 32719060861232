import React from 'react';
import styles from './EmulationBanner.less';

const EmulationBanner = ({ user }) => {
  if (user && user.inEmulation) {
    return (
      <div className={styles.emulationBanner}>
        You are viewing&nbsp;
        <strong data-pii-flag className="name">
          {user.name}
          &apos;s
        </strong>
        &nbsp;account below&nbsp;
        <span data-pii-flag className="email">
          ({user.email})
        </span>
      </div>
    );
  }
  return null;
};

export default EmulationBanner;

import React, { useContext } from 'react';
import {
  Axios,
  createAxiosInstance,
  apis,
  cepApis,
} from '@account-portal/react-axios';
import { AuthBoundaryContext } from '@account-portal/feynman-core';

import AuthBoundaryProvider from '../AuthBoundaryProvider/AuthBoundaryProvider';
import BuildContextProvider from '../BuildContextProvider/BuildContextProvider';
import { isBetaMode } from '../../helpers/isBetaMode';

const apisToUse = (env = 'test') => (isBetaMode() ? apis[env] : cepApis[env]);

const wrapperAuthInstance = createAxiosInstance(
  apisToUse(process.env.NODE_ENV),
  {
    withCache: false,
    withRetry: true,
    maxRetry: 2,
    retryTimeout: 4000,
    retryBackoff: 3,
  }
);

const wrapperInstance = authBoundary =>
  createAxiosInstance(
    apis[process.env.NODE_ENV],
    {
      withCache: true,
      withRetry: true,
    },
    authBoundary,
    isBetaMode
  );

const ContextConsumer = ({ children }) => {
  const { authBoundary } = useContext(AuthBoundaryContext);
  return (
    <Axios.Provider
      value={{
        axios: wrapperInstance(authBoundary),
        env: process.env.NODE_ENV,
      }}
    >
      {children}
    </Axios.Provider>
  );
};

const ContextProviders = ({ children }) => (
  <BuildContextProvider>
    <Axios.Provider
      value={{
        axios: wrapperAuthInstance,
        env: process.env.NODE_ENV,
      }}
    >
      <AuthBoundaryProvider>
        <ContextConsumer>{children}</ContextConsumer>
      </AuthBoundaryProvider>
    </Axios.Provider>
  </BuildContextProvider>
);

export default ContextProviders;

// mapping based on
// https://wiki.autodesk.com/pages/viewpage.action?spaceKey=EFDE&title=Universal+Header+-+Integration

const supportedLanguages = [
  'cs-CZ',
  'da-DK',
  'de-DE',
  'de-CH',
  'en-US',
  'en-GB',
  'en-CA',
  'en-AU',
  'en-NZ',
  'en-HK',
  'en-NL',
  'en-AE',
  'en-IN',
  'en-SG',
  'en-MY',
  'en-ZA',
  'en-TH',
  'es-ES',
  'es-MX',
  'es-AR',
  'fi-FI',
  'fr-FR',
  'fr-CA',
  'fr-CH',
  'fr-BE',
  'hu-HU',
  'it-IT',
  'it-CH',
  'ko-KR',
  'ja-JP',
  'nl-NL',
  'nl-BE',
  'no-NO',
  'pl-PL',
  'pt-PT',
  'pt-BR',
  'ru-RU',
  'sv-SE',
  'tr-TR',
  'zh-CN',
  'zh-TW',
];

const DELIMITER = '-';
const DEFAULT_LOCALE = 'en-US';

export const getLocale = (language = 'en', countryCode = 'US') => {
  const [languageCode] = language.split('_');
  const locale = `${languageCode}${DELIMITER}${countryCode}`;

  // looks for the exact language-countryCode supported local
  if (supportedLanguages.includes(locale)) return locale;

  // finds the first supported locale that starts with the user's language or returns the default
  return (
    supportedLanguages.find(lang =>
      lang.startsWith(`${languageCode}${DELIMITER}`)
    ) || DEFAULT_LOCALE
  );
};
